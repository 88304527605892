<template>
    <div class="menu-body">
        <Loader v-if="showLoader" />
        <Breadcrumb />
        <div class="outer-block">
            <div class="main-con">
                <div class="mat-info-con" style="padding-top: 0">
                    <div class="plant-dd" style="display: flex; width: 90%">

                        <div class="plant-dd" style="margin-left: 10px">
                            <label for="pillar" class="form-label select-lable lable-text"> Plant </label>
                            <select class="form-control select-text select-text-pd cust-st-icon" id="plantId"
                                :value="plantSelectedInAsmtHome" @change="plantChanged($event)">
                                <option v-for="plant in plantList" v-bind:key="plant.plantId" :value="plant.plantId">
                                    {{ plant.plantName + ", " + plant.regionCode }}
                                </option>
                            </select>
                        </div>
                        <div class="plant-dd" style="margin-left: 10px">
                            <label for="workcenter" class="form-label select-lable lable-text"> Work Center </label>
                            <select class="form-control select-text select-text-pd cust-st-icon" id="workcenterId"
                                v-model="workcenterId" @change="getMachineFilter()">
                                <option v-for="wc in workCenterList" v-bind:key="wc.workcenterId"
                                    :value="wc.workcenterId">
                                    {{ wc.workcenterName }}
                                </option>
                            </select>
                        </div>
                        <div class="plant-dd" style="margin-left: 10px">
                            <label for="Machine" class="form-label select-lable lable-text"> Machine </label>
                            <select class="form-control select-text select-text-pd cust-st-icon" id="machineId"
                                v-model="machineId" @change="apiCallReport()">
                                <option v-for="mc in machineList" v-bind:key="mc.machineId" :value="mc.machineId">
                                    {{ mc.machineName }}
                                </option>
                                <option disabled v-if="!machineList.length" :key="0" :value="0">
                                    No machine exist
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="button-dd">
                        <div title="Export to PDF" class="download-view action-btn" @click="downloadPdf">
                            <img alt="download" src="../../../assets/download_icon.svg" />
                        </div>
                    </div>
                </div>

                <div v-if="showGraph" class="mat-info-con" id="printable">
                    <div class="one-graph-per-row">
                        <div :id="'assessment-graph-div-' + 1">
                            <div class="main-tab-act default-font-color">
                                <div>Efficiency Improvement (OEE2%)</div>
                                <div style="font-size: 1.13rem">
                                    {{ plantId.plantName + ", " + plantId.regionCode + ' - ' }}
                                    {{ selectedWorkCenterName + " - "}}
                                    {{ selectedMachineName }}
                                </div>
                            </div>

                            <div v-if="graphDatas.oeeMonths && graphDatas.oeeMonths.length">
                                <canvas :height="120" :id="'assessment-graph-' + 1"
                                    style="background-color: white"></canvas>
                            </div>
                            <div class="graph-1-container">
                                <div class="graph-div">
                                    <div class="legends overOEE-value"></div>
                                    OEE OVER BASELINE - DEC1/RE1
                                </div>
                                <div class="graph-div">
                                    <div class="legends belowOEE-value"></div>
                                    OEE BELOW BASELINE - DEC1/RE1
                                </div>
                                <div class="graph-div">
                                    <div class="legends baseline1-value"></div>
                                    OEE BASELINE
                                </div>
                                <div class="graph-div">
                                    <div class="legends baseline2-value"></div>
                                    5% IMPROVEMENT BASE LINE (MIN. GOAL FOR DEC1/RE1)
                                </div>
                                <div class="graph-div">
                                    <div class="legends baseline3-value"></div>
                                    10% IMPROVEMENT LINE
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="one-graph-per-row" style="justify-content: center; display: flex; padding: 2rem 0">
                        <div style="width: 60%; display: flex; flex-wrap: wrap; justify-content: center">
                            <div v-for="pillar in pillarList" :key="pillar.pillarCode"
                                style="text-align: center; width: 50%">
                                <label class="module-title">{{ pillar.pillarCode }} STEP</label>
                                <div style="display: flex; justify-content: space-evenly; margin: 1rem 0 3rem 0">
                                    <div class="current-box"
                                        :class="{ 'offtrack-current': pillar.current < pillar.plan, 'ontrack-current': pillar.current >= pillar.plan }">
                                        <div class="button-sm">Current</div>
                                        <div class="score">{{ pillar.current }}</div>
                                    </div>
                                    <div class="plan-box">
                                        <div class="button-sm">Plan</div>
                                        <div class="score">{{ pillar.plan }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mat-info-con" style="justify-content: center" v-if="!showGraph">
                    <div class="no-data">
                        <img alt="no data" src="../../../assets/No_Data.svg" />
                        <span class="ed-date">No data found</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import "../../../common/css/CommonStyle.css";
import "../../../common/css/font-categories.css";
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Loader from "../../../components/common/Loader.vue";
import { mapGetters } from "vuex";
import Chart from "chart.js/auto";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";
import annotationPlugin from "chartjs-plugin-annotation";
import ApiCalls from "@/common/api/apiCalls";
import Api from "../../../common/constants/api";
import SpiderChartReport from "@/common/helper/SpiderChartReport.vue";
import { MonthPickerInput } from "vue-month-picker";

export default {
    name: "AssessmentReport",
    data() {
        return {
            showLoader: true,
            showGraph: false,
            plantList: [],
            workCenterList: [],
            machineList: [],
            plantId: {},
            workcenterId: 2,
            machineId: 1,
            graphDatas: {},
            pillarList: [
                { pillarCode: "DEC", current: 3, plan: 4 },
                { pillarCode: "CSD", current: 4, plan: 4 },
                { pillarCode: "WPO", current: 5, plan: 4 },
                { pillarCode: "RE", current: 2, plan: 4 }
            ],
            forAuditTargetCurrentTemplate: {
                type: "bar",
                data: {
                    labels: [],
                    datasets: [
                        {
                            label: "OEE",
                            data: [],
                            type: "bar",
                            borderRadius: {
                                topLeft: 150,
                                topRight: 150
                            },
                            borderSkipped: false,
                            datalabels: {
                                align: "end",
                                anchor: "end",
                                color: "#bbb",
                                labels: {
                                    value: {
                                        textStrokeColor: "#fff",
                                        textStrokeWidth: 3,
                                        font: {
                                            weight: 800,
                                            family: "BarlowB",
                                            size: 10
                                        },
                                        color: "#000"
                                    }
                                }
                            },
                            backgroundColor: ["#F75357"],
                            borderWidth: 1,
                            barPercentage: 0.8
                        }
                    ]
                },
                options: {
                    responsive: true,
                    lineTension: 0,
                    elements: {
                        bar: {
                            // hoverBackgroundColor:'yellow'
                        }
                    },
                    scales: {
                        y: {
                            display: true,
                            title: {
                                display: false,
                                text: "",
                                padding: { top: 30, left: 0, right: 0, bottom: 0 },
                                fontStyle: "bold",
                                fontSize: 16,
                                fontColor: "#333"
                            },
                            suggestedMax: 100,
                            ticks: { stepSize: 10 }
                        },
                        x: {
                            grid: {
                                display: false,
                                drawBorder: false,
                                drawOnChartArea: false,
                                drawTicks: false
                            }
                        }
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: ""
                        },
                        legend: {
                            display: false
                        },
                        autocolors: false,
                        annotation: {
                            annotations: {
                                line: {
                                    type: "line",
                                    yMin: 75,
                                    yMax: 75,
                                    borderColor: "#707070",
                                    borderWidth: 2
                                },
                                line1: {
                                    type: "line",
                                    yMin: 80,
                                    yMax: 80,
                                    borderColor: "#450584",
                                    borderWidth: 3,
                                    borderDash: [3, 10],
                                    borderRadius: 50
                                },
                                line2: {
                                    type: "line",
                                    yMin: 85,
                                    yMax: 85,
                                    borderColor: "#B8F99B",
                                    borderWidth: 2,
                                    borderDash: [9, 3]
                                }
                            }
                        }
                    }
                }
            }
        };
    },
    components: {
        Breadcrumb,
        Loader,
        MonthPickerInput
    },
    beforeCreate() {
        this.$store.dispatch("selectedMenu", {
            main: 3,
            subMenu: 8
        });
        this.$store.dispatch("breadcrumb", {
            title: "KPI Report",
            breadcrumb: [
                { name: "Home / ", primary: true, redirect: "/dashboard" },
                { name: "Reports / ", primary: true, redirect: "/" },
                { name: "Machine KPI Report", primary: false }
            ]
        });
    },
    mounted() { },
    created() {
        this.getPlantDetails();
        this.showLoader = true;
        this.showGraph = false;
    },
    mixins: [SpiderChartReport],
    computed: {
        ...mapGetters(["plantSelectedInAsmtHome", "loggedInUserData"]),
        selectedWorkCenterName() {
            let pos = this.workCenterList.findIndex((item) => item.workcenterId === this.workcenterId);
            return this.workCenterList[pos].workcenterName;
        },
        selectedMachineName() {
            let pos = this.machineList.findIndex((item) => item.machineId === this.machineId);
            return this.machineList[pos].machineName;
        },
    },
    methods: {
        downloadPdf() {
            this.download = true;
            this.showLoader = true;
            this.pdfMakeCall("kpi", ["printable"]);
            setTimeout(() => {
                this.showLoader = false;
            }, 10000);
        },
        onConfirmClick() {
            if (this.showOption) {
                this.apiCallReport();
            }
            this.showOption = false;
        },
        getPlantDetails() {
            ApiCalls.httpCallDetails(Api.GETPLANTLIST, "get").then((data) => {
                this.plantList = data.data;
                let plantId = this.plantSelectedInAsmtHome ? this.plantSelectedInAsmtHome : this.plantList[0].plantId;
                let index = 0;
                if (this.plantSelectedInAsmtHome) index = this.plantList.findIndex((el) => el.plantId == this.plantSelectedInAsmtHome);
                else if (this.loggedInUserData.plantId) {
                    let plantIndex = this.plantList.findIndex((el) => el.plantId == this.loggedInUserData.plantId);
                    if (plantIndex > -1) {
                        index = plantIndex;
                        plantId = this.plantList[index].plantId;
                    }
                } else if (this.loggedInUserData.regionName) {
                    let plantIndex = this.plantList.findIndex((el) => el.regionName == this.loggedInUserData.regionName);
                    if (plantIndex > -1) {
                        index = plantIndex;
                        plantId = this.plantList[index].plantId;
                    }
                }
                this.plantId = this.plantList[index];
                this.$store.dispatch("plantSelectedInAsmtHome", plantId);
                this.getWorkCenterDetails();
            });
        },
        plantChanged(event) {
            this.showLoader = true;

            this.$store.dispatch("plantSelectedInAsmtHome", event.target.value);
            this.plantId = this.plantList.find((el) => el.plantId == event.target.value);
            this.getWorkCenterDetails();
        },
        getWorkCenterDetails() {
            ApiCalls.httpCallDetails(Api.MRWORKCENTERALL, "post", { regionId: this.plantId.regionId }).then((data) => {
                this.workCenterList = data.data;
                this.workcenterId = data.data[0].workcenterId;
                this.getMachineFilter();
            });
        },
        getMachineFilter() {
            this.showLoader = true;

            let machinePayload = {
                plantId: this.plantId.plantId,
                workcenterId: this.workcenterId,
                regionId: this.plantId.regionId
            };

            ApiCalls.httpCallDetails(Api.MRMACHINEDROPDOWN, "post", machinePayload).then((data) => {
                if (data.data.length) {
                    this.machineList = data.data;
                    this.machineId = data.data[0].machineId;
                    this.apiCallReport();
                } else {
                    this.showGraph = false;
                    this.machineList = [];
                    this.machineId = 0
                    this.showLoader = false;
                }
            });
        },
        apiCallReport() {
            let req = {
                plantId: this.plantId.plantId,
                wcId: this.workcenterId,
                machineId: this.machineId
            };
            this.showLoader = true;
            this.showGraph = false;
            this.graphDatas = {};

            ApiCalls.httpCallDetails(Api.GETKPIOEEREPORT, "post", req).then((data) => {
                console.log(data);
                this.graphDatas = data.data;
                this.pillarList = this.graphDatas.kpioeeSteps && this.graphDatas.kpioeeSteps.length ? JSON.parse(JSON.stringify(this.graphDatas.kpioeeSteps)) : []
                this.pillarList.forEach((step) => {
                    step.current = step.current ? step.current : 0;
                    step.plan = step.plan ? step.plan : 0;
                });
                this.showGraph = true;
                setTimeout(() => {
                    this.machineGraph();
                }, 1000);
            });
        },
        machineGraph() {
            this.printData = [];

            this.printData.push("assessment-graph-div-" + 1);

            let machineData = JSON.parse(JSON.stringify(this.forAuditTargetCurrentTemplate));

            machineData.options.plugins.annotation.annotations.line.yMin = this.graphDatas.oeeBaseLine;
            machineData.options.plugins.annotation.annotations.line.yMax = this.graphDatas.oeeBaseLine;

            machineData.options.plugins.annotation.annotations.line1.yMin = this.graphDatas.imp5Per;
            machineData.options.plugins.annotation.annotations.line1.yMax = this.graphDatas.imp5Per;

            machineData.options.plugins.annotation.annotations.line2.yMin = this.graphDatas.imp10Per;
            machineData.options.plugins.annotation.annotations.line2.yMax = this.graphDatas.imp10Per;

            machineData.data.labels = this.graphDatas.oeeMonths;
            machineData.data.datasets[0].data = this.graphDatas.oeeCurrent;
            machineData.data.datasets[0].backgroundColor = this.graphDatas.oeeCurrent.map((current, pos) => (current < this.graphDatas.oeeBaseLine ? "#f75357" : "#109b10"));

            Chart.register(ChartJsPluginDataLabels);
            Chart.register(annotationPlugin);
            let ctx = document.getElementById("assessment-graph-" + 1);
            let obj = new Chart(ctx, machineData);
            obj.draw()
            this.showLoader = false;
        }
    }
};
</script>

<style scoped>
/* tabs */

.tab-titles {
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tab-options {
    display: flex;
    margin-top: 2rem;
}

.tab-option-text {
    font: 1.16rem "BarlowR", sans-serif;
    cursor: pointer;
}

.in-action {
    margin-right: 1.688em;
}

.tab-active-option {
    font: 1.18rem "BarlowM", sans-serif;
}

.active-tab-scroll-hr {
    border-top: 3px #274ba7 solid;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    opacity: 1 !important;
    margin-top: 1rem;
}

.select-dd {
    width: 10%;
}

.button-dd {
    display: inline-flex;
}


.outer-block {
    background-color: #fff;
    border-radius: 6px;
    padding: 1rem 0;
}

.hr {
    position: relative;
    top: -26px;
}

/* tabs */
.lable-text {
    z-index: 2;
}

.multi-select {
    position: relative;
}

.multi-select ul {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #ddd;
    box-shadow: 2px 1px 4px 0.1rem rgb(0 0 0 / 25%);
    border-top: 0;
    border-radius: 0 0 3px 3px;
    left: 0px;
    padding: 8px 8px;
    position: absolute;
    top: 0;
    width: 20rem;
    list-style: none;
    max-height: 150px;
    z-index: 2;
    background-color: #fff;
    overflow: auto;
}

.multi-select ul li {
    padding: 1rem;
}

.graph-div {
    display: flex;
    padding-right: 0.5rem;
    align-items: center;
}

.main-con {
    background: #fff;
    margin-top: 1em;
    border-radius: 6px;
}

.mat-info-con {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 2rem;
}

.download-view {
    border-radius: 2rem;
    border: 1px solid #274ba7;
    margin-left: 1rem;
    font-size: 1.33rem;
    margin-right: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.download-view:hover {
    background: #e3e8f7;
}

.action-btn {
    border: 1px #274ba7 solid;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    width: 32px;
    cursor: pointer;
}

.plant-dd {
    width: 20.5%;
}

.one-graph-per-row {
    width: 100% !important;
}

.two-graph-per-row {
    width: 49% !important;
}

.graph-1-container {
    width: 100%;
    padding: 2rem 0;
    color: #505d6f;
    font: 1rem BarlowR, sans-serif;
    display: flex;
    justify-content: space-between;
}

.graph-2-container {
    width: 100%;
    padding: 1rem 2rem;
    color: #505d6f;
    font: 1rem BarlowR, sans-serif;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.value-font {
    font: 1.16rem "BarlowSB", sans-serif;
}

.legends {
    margin: 0 0.5rem;
    border: 1px solid #d3d3d3;
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
}

.overOEE-value {
    background-color: #109b10;
}

.belowOEE-value {
    background-color: #f75357;
}

.baseline1-value {
    background-color: #707070;
    width: 2rem !important;
    border: 1px solid #707070;
    height: 0.2rem;
    border-radius: 0 !important;
}

.baseline2-value {
    width: 2rem !important;
    border: 0px dotted #450584;
    background-color: transparent;
    height: 0;
    border-radius: 0 !important;
    border-top: 5px dotted #450584;
}

.baseline3-value {
    border: 0px;
    background-color: transparent;
    width: 2rem !important;
    height: 0;
    border-radius: 0 !important;
    border-top: 5px dashed #b8f99b;
}

.module-title {
    text-align: center;
}

.current-box {
    width: 8.33rem;
    height: 8.33rem;
    text-align: center;
    display: grid;
    align-content: center;
    border-radius: 0.5rem;
}

.offtrack-current {
    background: #fff1f9;
}

.ontrack-current {
    background: #f1fbff;
}

.plan-box {
    width: 8.33rem;
    height: 8.33rem;
    text-align: center;
    background: #f3f3f3;
    border-radius: 0.5rem;
    display: grid;
    align-content: center;
}

.score {
    font: 2.58rem "BarlowM", sans-serif;
}


@media only screen and (max-width: 767px) {
    .mat-info-con {
        display: block !important;
    }

    .plant-dd {
        width: 100% !important;
    }
}
</style>
